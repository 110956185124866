import tableState from "../../../templates/table/state";

const customizedTableState = tableState();
customizedTableState.pagination.rowsPerPage = 50;

export default () => ({
  ...customizedTableState,
  lead: {},
  country: {},
  language: {},
  subject: {},
  submission: {},
  website: {},
  offer: {},
  company: {},
  buddy: {},
  user: {},
  submissionRefinement: {},
  refinementRule: {}
});
